import 'bootstrap/dist/css/bootstrap.min.css';
// import 'popper.js/dist/esm/popper.min';
// import 'bootstrap/dist/js/bootstrap.min';

import 'angular-material/angular-material.min.css';

// import 'angular';
import 'md-color-picker/dist/mdColorPicker.min.css';
// import 'md-color-picker';
// import 'sortablejs';
// import 'popper.js';

// import 'worker-loader!node-vibrant/dist/vibrant.worker.min.js';
import 'node-vibrant/lib/bundle.worker.js';

import 'cropperjs/dist/cropper.min.css';

import '@fortawesome/fontawesome-free/css/all.css';

// // https://github.com/Microsoft/monaco-editor/issues/18
// var loaderScript = document.createElement('script');
// loaderScript.src = 'vs/loader.js';
// loaderScript.addEventListener('load', function () {
// 	window.waitForEditor = new Promise(function (resolve) {
// 		window.require(['vs/editor/editor.main'], function () {
// 			resolve();
// 		});
// 	});
// });
// document.body.appendChild(loaderScript);
// https://github.com/Microsoft/monaco-editor/issues/759
// import 'monaco-editor';

// self.MonacoEnvironment = {
// 	getWorkerUrl: function (moduleId, label) {
// 		if (label === 'json') {
// 			return './json.worker.bundle.js';
// 		}
// 		if (label === 'css') {
// 			return './css.worker.bundle.js';
// 		}
// 		if (label === 'html') {
// 			return './html.worker.bundle.js';
// 		}
// 		if (label === 'typescript' || label === 'javascript') {
// 			return './ts.worker.bundle.js';
// 		}
// 		return './editor.worker.bundle.js';
// 	}
// };
